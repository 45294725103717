import React, { useState, useEffect } from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import HeaderOneTop from "@/components/header-one-top";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SliderTop from "@/components/news/slider-top";
import Content from "@/components/news/content";
import api from "@/axios";
import { navigate } from "gatsby";

const News = ({ params }) => {
  if (typeof document !== "undefined") {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);
  }

  const [apiData, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/news/o/get/${params.id}`, {
          params: { type: "post" },
        });
        if (
          response.data &&
          response.data.status &&
          response.data.status == "error"
        ) {
          navigate("/aktualnosci", { replace: true });
        }
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        navigate("/aktualnosci", { replace: true });
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        {loading && <span className="loader"></span>}
        {!loading && (
          <Layout PageTitle="Aktualności">
            <HeaderOneTop />
            <HeaderOne />
            <SliderTop data={apiData} />
            <Content data={apiData} />
            <Footer />
          </Layout>
        )}
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default News;
